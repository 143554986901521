<template>
  <div class="main-div vasion-calc-height">
    <div class="inner-div">
      <VasionGenericEmptyIcon id="vasion-empty-icon" />
      <div class="vasion-large-message">
        {{ greetingMessage }}
      </div>
      <div class="vasion-page-subheader instructional-message-div">
        {{ instructionalMessage }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptyAdminView',
  data: function () {
    return {
      greetingMessage: 'Hello!',
    }
  },
  computed: {
    instructionalMessage() { return 'Please select a section to get started!' },
  },
  async created() {
    this.greetingMessage = await this.$store.dispatch('common/getEmptyAppGreeting')
  },
  methods: { },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .inner-div {
    padding-top: 124px;
    width: 100%;
  }

  .instructional-message-div {
    margin-top: 24px;
  }

  .main-div {
    background-color: white;
    text-align: center;
    width: 100%;
  }

  #vasion-empty-icon{
    width: 356px;
    height: 376px;
  }

  @media(max-width: $phone){
    #vasion-empty-icon{
      width: 90%;
    }

    .vasion-large-message{
      font-size: 30px;
    }

    .inner-div{
      padding-top: 10px;
    }
  }
</style>
